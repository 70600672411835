import { Service } from '@feathersjs/feathers'
import { Audit } from '@/components/ChecklistBuilder/Misc/audit.interface'
import { getService } from '@/helpers/feathers'
import { Connection } from '@/offline/connection.enum'
import { OfflineEntry } from '@/offline/offline-entry.interface'

/**
 * Löscht das angegebene Offline-Audit.
 *
 * @param basicSelector - Basic-Selector der Audits
 * @param auditID - ID des Offline-Audits, welches gelöscht werden soll
 * @returns - ID des Audits, wenn das Löschen erfolgreich wahr.
 */
export async function removeOfflineAudit(
  basicSelector: string,
  auditID: number
): Promise<Audit> {
  if (!basicSelector || (basicSelector = basicSelector.trim()) === '') {
    throw new Error(`param 'basicSelector' is empty`)
  }
  if (auditID <= 0) {
    throw new Error(`offline audit id is invalid`)
  }

  const [
    serviceAudit,
    serviceValues,
    serviceLocation,
    servicePermissions,
    serviceProperties,
    serviceStatus,
    serviceVariables
  ] = await Promise.all<Service<OfflineEntry>>([
    getService('audits/offline', Connection.Offline),
    getService('checklists/cells/values', Connection.Offline),
    getService('checklists/location', Connection.Offline),
    getService('checklists/permissions', Connection.Offline),
    getService('checklists/properties', Connection.Offline),
    getService('checklists/status', Connection.Offline),
    getService('checklists/variables', Connection.Offline)
  ])

  const audit: Audit | null = await serviceAudit.get(auditID).then(
    (entry): Audit => entry as unknown as Audit,
    (): null => null
  )
  if (audit === null || audit.id !== auditID) {
    throw new Error(`offline audit #${auditID} not found`)
  }

  const selector = `${basicSelector}_${auditID}`
  const collectionID =
    (Array.isArray(audit.checklists) ? audit.checklists : [])[0] || null
  const queryFindValues = {
    selector: selector,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    collection_id: collectionID
  }
  const [
    cellValuesID,
    locationID,
    permissionID,
    propertiesID,
    statusID,
    variablesID
  ] = await Promise.all([
    serviceValues.find({ query: queryFindValues }).then(
      async (result): Promise<number[]> => {
        let loadedCells: number[][] = []

        if ('total' in result && typeof result.total === 'number') {
          const total: number = result.total as number
          const limit: number = result.limit as number

          if (total > limit) {
            const loading: Promise<number[]>[] = []

            for (let offset = limit; offset < total; offset += limit) {
              loading.push(
                serviceValues
                  .find({ query: { ...queryFindValues, $offset: offset } })
                  .then(
                    (result): number[] =>
                      Object.values(result).map((x): number => x.id),
                    (): number[] => []
                  )
              )
            }

            loadedCells = await Promise.all(loading)
          }
        }

        const valuesID = loadedCells.flat(1)
        valuesID.push(...Object.values(result).map((x): number => x.id))
        valuesID.filter(
          (x, i, a): boolean =>
            typeof x === 'number' && x > 0 && !isNaN(x) && a.indexOf(x) === i
        )
        return valuesID
      },
      (): number[] => []
    ),
    serviceLocation.get(auditID).then(
      (x): number | null => (x.id ? x.id : null),
      (): null => null
    ),
    servicePermissions.get(auditID).then(
      (x): number | null => (x.id ? x.id : null),
      (): null => null
    ),
    serviceProperties.get(auditID).then(
      (x): number | null => (x.id ? x.id : null),
      (): null => null
    ),
    serviceStatus.get(auditID).then(
      (x): number | null => (x.id ? x.id : null),
      (): null => null
    ),
    serviceVariables
      .find({
        query: { selector: selector }
      })
      .then(
        (x): number | null =>
          'data' in x &&
          Array.isArray(x.data) &&
          typeof x.total === 'number' &&
          x.total > 0 &&
          typeof x.data[0].id === 'number'
            ? x.data[0].id
            : null,
        (): null => null
      )
  ])

  const requests = []

  if (typeof auditID === 'number') {
    requests.push(
      serviceAudit.remove(auditID).then(
        (result): OfflineEntry[] => [result],
        (): OfflineEntry[] => []
      )
    )
  }

  if (Array.isArray(cellValuesID) && cellValuesID.length) {
    requests.push(
      serviceValues.remove(null, { query: { id: { $in: cellValuesID } } })
    )
  }

  if (typeof locationID === 'number') {
    requests.push(
      serviceLocation.remove(locationID).then(
        (result): OfflineEntry[] => [result],
        (): OfflineEntry[] => []
      )
    )
  }

  if (typeof permissionID === 'number') {
    requests.push(
      servicePermissions.remove(permissionID).then(
        (result): OfflineEntry[] => [result],
        (): OfflineEntry[] => []
      )
    )
  }

  if (typeof propertiesID === 'number') {
    requests.push(
      serviceProperties.remove(propertiesID).then(
        (result): OfflineEntry[] => [result],
        (): OfflineEntry[] => []
      )
    )
  }

  if (typeof statusID === 'number') {
    requests.push(
      serviceStatus.remove(statusID).then(
        (result): OfflineEntry[] => [result],
        (): OfflineEntry[] => []
      )
    )
  }

  if (typeof variablesID === 'number') {
    requests.push(
      serviceVariables.remove(variablesID).then(
        (result): OfflineEntry[] => [result],
        (): OfflineEntry[] => []
      )
    )
  }

  await Promise.all(requests)

  return audit
}

import { Service } from '@feathersjs/feathers'
import { getService } from '@/helpers/feathers'
import { Connection } from '@/offline/connection.enum'
import { OfflineEntry } from '@/offline/offline-entry.interface'

/**
 * Löscht das angegebene Online-Audit.
 *
 * @param basicSelector - Basic-Selector der Audits
 * @param auditID - ID des Online-Audits, welches gelöscht werden soll
 * @returns - ID des Audits, wenn das Löschen erfolgreich wahr.
 */
export async function removeOnlineAudit(
  basicSelector: string,
  auditID: number
): Promise<number> {
  if (!basicSelector || (basicSelector = basicSelector.trim()) === '') {
    throw new Error(`param 'basicSelector' is empty`)
  }
  if (auditID <= 0) {
    throw new Error(`online audit id is invalid`)
  }

  const baseQuery = {
    query: {
      $selector: basicSelector
    }
  }

  const [
    serviceAudit,
    serviceAuditChecklistStatus,
    serviceAuditLocation,
    serviceAuditProperty,
    serviceChecklistsVariablesValues,
    serviceChecklistsValues
  ] = await Promise.all<Service<OfflineEntry>>([
    getService('audit', Connection.Online),
    getService('audit-checklist-status', Connection.Online),
    getService('audit-location', Connection.Online),
    getService('audit-property', Connection.Online),
    getService('checklists/variables/values', Connection.Online),
    getService('checklists/value', Connection.Online)
  ])

  await Promise.all([
    serviceChecklistsVariablesValues.remove(auditID, baseQuery).then(
      (): boolean => true,
      (): boolean => false
    ),
    serviceChecklistsValues.remove(auditID, baseQuery).then(
      (): boolean => true,
      (): boolean => false
    ),
    serviceAuditLocation.remove(auditID).then(
      (): boolean => true,
      (): boolean => false
    ),
    serviceAuditProperty.remove(auditID).then(
      (): boolean => true,
      (): boolean => false
    ),
    serviceAuditChecklistStatus.remove(auditID).then(
      (): boolean => true,
      (): boolean => false
    )
  ])

  await serviceAudit.remove(auditID)

  return auditID
}

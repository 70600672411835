import { OfflineEntry } from './offline-entry.interface'

/**
 * Entfernt alle Eigenschaften die automatisch Offline hinzugefügt werden und
 * nicht zum eigentlichen Eintrag dazu gehören.
 *
 * @param entry - Offline-Eintrag mit den Offline-Eigenschaften.
 * @param override - Überschreibt die entsprechende Werte aus [[entry]].
 * @returns -  Kopie des Offline-Eintrages ohne die Offline-Eigenschaften.
 */
export function removeOfflineProperties(
  entry: OfflineEntry,
  override: OfflineEntry = {}
): OfflineEntry {
  const clone: OfflineEntry = { ...entry, ...override }

  delete clone._offlineAt
  delete clone._existsOnlyOffline

  return clone
}

import { Service } from '@feathersjs/feathers'
import { Audit } from '@/components/ChecklistBuilder/Misc/audit.interface'
import { ImportSource } from '@/components/ChecklistBuilder/Misc/import-source.enum'
import { getService, onReady } from '@/helpers/feathers'
import { removeOfflineAudit } from '@/helpers/audits/remove-offline-audit'
import { removeOnlineAudit } from '@/helpers/audits/remove-online-audit'
import { Connection } from '@/offline/connection.enum'
import { OfflineEntry } from '@/offline/offline-entry.interface'
import { removeOfflineProperties } from '@/offline/remove-offline-properties'
import store from '@/store'

/**
 * Diese Funktion konvertiert einen Offline-Audit zu einem Online-Audit. Wenn
 * dieses Erfolgreich funktioniert hat, wird das Offline-Audit gelöscht.
 *
 * @param basicSelector - Basic-Selector der Audits
 * @param audit -Offline-Audit welcher konvertiert werden soll
 * @returns onlineAudit - das Online-Audit
 */
export async function convertOfflineAudit(
  basicSelector: string,
  audit: Audit
): Promise<Audit> {
  if (!basicSelector || (basicSelector = basicSelector.trim()) === '') {
    throw new Error(`param 'basicSelector' is empty`)
  }

  const offlineAuditID = audit.id
  if (typeof offlineAuditID !== 'number' || offlineAuditID <= 0) {
    throw new Error(`param 'audit': audit id is missing or invalid`)
  }

  const collectionID = (audit.checklists || [])[0]
  if (typeof collectionID !== 'number' || collectionID <= 0) {
    throw new Error(`param 'audit': collectionID is missing or invalid`)
  }

  // alle Services laden (Online und Offline-Services)
  const [
    // Alle Online-Services
    serviceOnlineAudit,
    serviceOnlineAuditChecklistStatus,
    serviceOnlineAuditLocation,
    serviceOnlineAuditProperty,
    serviceOnlineChecklistsVariablesValues,
    serviceOnlineChecklistsValues,
    // Alle Offline-Services
    serviceOfflineChecklistsCollectionDeep,
    serviceOfflineChecklistsCellValues,
    serviceOfflineChecklistsLocation,
    serviceOfflineChecklistsPermissions,
    serviceOfflineChecklistsProperties,
    serviceOfflineChecklistsStatus,
    serviceOfflineChecklistsVariables
  ] = await Promise.all<Service<OfflineEntry>>([
    // Alle Online-Services
    getService('audit', Connection.Online),
    getService('audit-checklist-status', Connection.Online),
    getService('audit-location', Connection.Online),
    getService('audit-property', Connection.Online),
    getService('checklists/variables/values', Connection.Online),
    getService('checklists/value', Connection.Online),
    // Alle Offline-Services
    getService('checklists-collections/deep', Connection.Offline),
    getService('checklists/cells/values', Connection.Offline),
    getService('checklists/location', Connection.Offline),
    getService('checklists/permissions', Connection.Offline),
    getService('checklists/properties', Connection.Offline),
    getService('checklists/status', Connection.Offline),
    getService('checklists/variables', Connection.Offline),
    // Sonstiges
    new Promise((success): void => onReady(success))
  ])

  const offlineSelector = `${basicSelector}_${offlineAuditID}`
  const dateNow = new Date(Date.now())
  // alle Daten des Offline-Audits laden
  const userID = store.getters['user/id'] as number
  const queryFindValues = {
    selector: offlineSelector,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    collection_id: collectionID
  }
  const [
    dataCollection,
    dataCellValues,
    dataLocation,
    dataPermission,
    dataProperties,
    dataStatus,
    dataVariables
  ] = await Promise.all([
    serviceOfflineChecklistsCollectionDeep
      .get(collectionID)
      .catch((): null => null),
    serviceOfflineChecklistsCellValues.find({ query: queryFindValues }).then(
      async (result): Promise<OfflineEntry> => {
        let loadedCells: OfflineEntry[] = []

        if ('total' in result && typeof result.total === 'number') {
          const total: number = result.total as number
          const limit: number = result.limit as number

          if (total > limit) {
            const loading: Promise<OfflineEntry>[] = []

            for (let offset = limit; offset < total; offset += limit) {
              loading.push(
                serviceOfflineChecklistsCellValues
                  .find({ query: { ...queryFindValues, $offset: offset } })
                  .then(
                    (result): OfflineEntry => result as OfflineEntry,
                    (): OfflineEntry => ({})
                  )
              )
            }

            loadedCells = await Promise.all(loading)
          }
        }

        const cellValues: OfflineEntry = Object.assign(result, ...loadedCells)
        delete cellValues.total
        delete cellValues.limit
        return cellValues
      },
      (): null => null
    ),
    serviceOfflineChecklistsLocation
      .get(offlineAuditID)
      .catch((): null => null),
    serviceOfflineChecklistsPermissions
      .get(offlineAuditID)
      .catch((): null => null),
    serviceOfflineChecklistsProperties
      .get(offlineAuditID)
      .catch((): null => null),
    serviceOfflineChecklistsStatus.get(offlineAuditID).catch((): null => null),
    serviceOfflineChecklistsVariables
      .find({
        query: { selector: offlineSelector }
      })
      .then(
        (x): OfflineEntry | null =>
          'data' in x &&
          Array.isArray(x.data) &&
          typeof x.total === 'number' &&
          x.total > 0
            ? x.data[0]
            : null,
        (): null => null
      )
  ])

  // Offline-Audit als Offline-Audit speichern
  const dataOfflineAudit = removeOfflineProperties({
    ...audit,
    createdBy: userID,
    importSource: ImportSource.Offline,
    importDate: dateNow
  })
  delete dataOfflineAudit.id
  const onlineAudit: Audit | null = await serviceOnlineAudit
    .create(dataOfflineAudit)
    .then(
      (result): Audit =>
        Array.isArray(result)
          ? (result[0] as unknown as Audit)
          : (result as unknown as Audit),
      (): null => null
    )
  if (!onlineAudit || !onlineAudit.id || onlineAudit.id <= 0) {
    throw new Error(`create from offline entry failed`)
  }
  const onlineAuditID = onlineAudit.id as number
  const onlineSelector = `${basicSelector}_${onlineAuditID}`

  // Online-Content: Checklisten-Variablen
  const contentVariables = {
    values: ((dataVariables || {}).Values || {}) as {
      [key: string]: string
    },
    settings: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      selector_global: basicSelector,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      selector_local: onlineSelector,
      date: dateNow.toISOString(),
      // eslint-disable-next-line @typescript-eslint/naming-convention
      employee_id: userID,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      collection_id: collectionID,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      emkg_id: null,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      substance_id: null,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      location_id: null,
      categoryID: onlineAudit.categoryID || null,
      auditID: onlineAuditID,
      accidentReportID: null
    }
  }

  // Online-Content: Checklisten-Values
  const contentCells: OfflineEntry[][][] = []
  const collection = (dataCollection || {}).Checklists as OfflineEntry[]
  const contentValues = {
    data: contentCells,
    selector: onlineSelector,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    collection_id: collectionID,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    employee_id: userID,
    logging: 1
  }

  if (Array.isArray(collection) && collection.length && dataCellValues) {
    for (const checklist of collection) {
      const tempChecklist: OfflineEntry[][] = []
      const columns = (checklist || {}).ChecklistCells as OfflineEntry[][]

      if (Array.isArray(columns) && columns.length) {
        for (const column of columns) {
          const tempColumn: OfflineEntry[] = []

          if (Array.isArray(column) && column.length) {
            for (let cell, i = 0; (cell = column[i]); i++) {
              if (cell.id && dataCellValues.hasOwnProperty(cell.id)) {
                const value = (dataCellValues[cell.id] || {}) as OfflineEntry
                tempColumn[i] = removeOfflineProperties(value, {
                  id: 0,
                  selector: onlineSelector
                })
              }
            }
          }

          tempChecklist.push(tempColumn)
        }
      }

      contentCells.push(tempChecklist)
    }
  }

  // Online-Content: Location
  const contentLocation =
    dataLocation !== null && Array.isArray(dataLocation.locations)
      ? dataLocation.locations.map(
          (x: number): OfflineEntry => ({
            auditID: onlineAuditID,
            locationID: x
          })
        )
      : []

  // Online-Content: Properties
  const contentProperties =
    dataProperties !== null && Array.isArray(dataProperties.properties)
      ? dataProperties.properties.map(
          (x: number): OfflineEntry => ({
            auditID: onlineAuditID,
            propertyID: x
          })
        )
      : []

  // Online-Content Status
  const contentListStatus: OfflineEntry[] = []
  const contentStatus = {
    auditID: onlineAuditID,
    status: contentListStatus
  }
  if (dataPermission !== null) {
    const listStatus = (dataPermission.listStatus || []) as OfflineEntry[]

    for (const status of listStatus) {
      const cloneStatus: OfflineEntry = removeOfflineProperties(status)

      if (dataStatus && status.id && dataStatus.hasOwnProperty(status.id)) {
        cloneStatus.status = dataStatus[status.id]
      }

      cloneStatus.checklistID = status.id
      cloneStatus.visibilityRights = status.visibility_rights
      cloneStatus.editabilityRights = status.editability_rights
      cloneStatus.affectedEmployee = !!status.affected_employee
      cloneStatus.executor = !!status.executor
      cloneStatus.responsible = !!status.responsible
      cloneStatus.filterByLocations = !!status.filter_by_locations
      cloneStatus.allLevels = !!status.match_all_levels
      cloneStatus.skipLevels = status.match_skip_levels || 0

      delete cloneStatus.id
      delete cloneStatus.visibility_rights
      delete cloneStatus.editability_rights
      delete cloneStatus.affected_employee
      delete cloneStatus.filter_by_locations
      delete cloneStatus.match_all_levels
      delete cloneStatus.match_skip_levels

      contentListStatus.push(cloneStatus)
    }
  }

  // alles speichern
  const [
    resultVariables,
    resultValues,
    resultLocation,
    resultProperties,
    resultStatus
  ] = await Promise.all([
    Object.keys(contentVariables.values).length
      ? serviceOnlineChecklistsVariablesValues.create(contentVariables).then(
          (x): boolean => !!x,
          (): boolean => false
        )
      : true,
    serviceOnlineChecklistsValues.create(contentValues).then(
      (x): boolean => !!x,
      (): boolean => false
    ),
    contentLocation.length
      ? serviceOnlineAuditLocation.create(contentLocation).then(
          (x): boolean => !!x,
          (): boolean => false
        )
      : true,
    contentProperties.length
      ? serviceOnlineAuditProperty.create(contentProperties).then(
          (x): boolean => !!x,
          (): boolean => false
        )
      : true,
    contentStatus.status.length
      ? serviceOnlineAuditChecklistStatus.create(contentStatus).then(
          (x): boolean => !!x,
          (): boolean => false
        )
      : true
  ])

  if (
    !resultVariables ||
    !resultValues ||
    !resultLocation ||
    !resultProperties ||
    !resultStatus
  ) {
    await removeOnlineAudit(basicSelector, onlineAuditID).catch(
      (): null => null
    )
    throw new Error(`failed to create audit checklist and/or audit relations`)
  }

  await removeOfflineAudit(basicSelector, offlineAuditID)

  return onlineAudit
}
